<template>
  <div>
    <div class="bottle-page-top"></div>
    <div class="bottle-page-num-content">
      <div class="bottle-page-num-content-left">当前瓶盖数</div>
      <div style="width: 1px; height: 34px; background: #f7f7f7"></div>
      <div class="bottle-page-num-content-nums">
        <img
          src="https://cdn.vgn.cn/static/downloadpage/%E7%93%B6%E7%9B%96%402x%20(1).png"
          alt=""
        />
        <div style="margin-left: 3px">{{ reward_point }}</div>
      </div>
      <div class="bottle-page-num-content-right" @click="toCheckDetail">
        <div>查看明细</div>
        <img
          style="width: 16px; height: 16px"
          src="https://cdn.vgn.cn/static/downloadpage/icon%EF%BC%8Farrow_right%402x.png"
          alt=""
        />
      </div>
    </div>
    <div class="bottle-page-sign-content">
      <div class="bottle-page-sign-content-left">已连续签到</div>
      <div class="bottle-page-count">
        <div class="tran-pos" v-if="dayRuleData.missing_days > 0"></div>
        <div
          class="cut-sign-content"
          v-if="dayRuleData.missing_days > 0"
          @click="toSign"
        >
          <span style="color: #ac5d00">断签</span>
          <span style="color: #ff3648">{{ dayRuleData.missing_days }}</span>
          <span style="color: #ac5d00">天,</span>
          <span style="color: #ac5d00">点击补签</span>
          <span style="color: #ff3648; margin-left: 8px">去补签></span>
          <img
            src="https://cdn.vgn.cn/static/newnewnew/222/Group%2059%402x.png"
            alt=""
          />
        </div>
        <div class="bottle-page-time">
          <div class="bottle-page-time-content" :style="unSign">
            {{ SignT }}
            <div class="bottle-page-time-content-line"></div>
          </div>
          <div class="bottle-page-time-content" :style="unSign">
            {{ SignH }}
            <div class="bottle-page-time-content-line"></div>
          </div>
          <div class="bottle-page-time-content" :style="unSign">
            {{ SignTW }}
            <div class="bottle-page-time-content-line"></div>
          </div>
          <div class="bottle-page-time-content" :style="unSign">
            {{ SignO }}
            <div class="bottle-page-time-content-line"></div>
          </div>
          <div style="font-weight: 800">天</div>
        </div>
        <div
          v-if="BlindBoxFreeData"
          style="width: 1px; height: 64px; background: #f7f7f7"
        ></div>
        <div
          class="blind-box-free"
          v-if="BlindBoxFreeData"
          @click="toBlindBoxHandler"
        >
          <div class="blind-box-covers">
            <div class="blind-box-pos-receive" v-if="BlindBoxFreeData.receive">
              已开启
            </div>
            <div v-else class="blind-box-pos-receive-un">可开启</div>
            <div
              v-for="(temp, index) in covers"
              :class="`cover cover-${index}`"
              :key="index"
            >
              <img alt="" :src="temp" />
            </div>
          </div>
          <div class="blind-box-name">每日补给箱</div>
        </div>
      </div>
      <div class="bottle-page-button-more" @click="jumpBottle">
        获取更多瓶盖
      </div>
    </div>
    <div class="bottle-page-swipe">
      <div class="bottle-page-swipe-content">
        <div
          :class="{ bottlePageSwipeActive: checkId == 0 }"
          @click="BottleChange"
        >
          瓶盖兑换
        </div>
        <div
          :class="{ bottlePageSwipeActive: checkId == 1 }"
          style="margin-left: 38px"
          @click="BottleGuess"
        >
          瓶盖抽奖
        </div>
      </div>
      <div class="bottle-page-swipe-line" :style="TranX30"></div>
    </div>

    <div :style="{ display: checkId != 0 ? 'none' : 'block' }">
      <list
        v-model="loadeded"
        :finished="finished"
        finished-text="没有更多了"
        @load="loadCouponData"
      >
        <div class="bottle-change-card-flex">
          <coupon-card
            v-for="(item, index) in CounponData"
            :items="item"
            :key="index"
          />
        </div>
      </list>
    </div>

    <div :style="PosLottery">
      <div
        style="
          padding-left: 12px;
          padding-bottom: 16px;
          color: #222731;
          font-size: 14px;
        "
      >
        当前抽奖
      </div>
      <lottery-card
        v-for="(item, index) in lotteryData"
        :items="item"
        :key="index"
      />
      <div
        style="
          padding-left: 12px;
          padding-bottom: 16px;
          color: #222731;
          font-size: 14px;
        "
      >
        往期抽奖
      </div>
      <list
        v-model="load"
        :finished="finish"
        finished-text="没有更多了"
        @load="loadData"
      >
        <lottery-card-filter
          v-for="(item, index) in lotteryData"
          :items="item"
          :key="index"
        />
      </list>
    </div>
    <SignPop
      :signDailyData="signDailyData"
      v-if="showSignPopState"
      @confirm="confirm"
    />
    <div class="unsign-pop" v-if="SignPopState">
      <div class="content-pop">
        <div
          class="vip-tag"
          v-if="
            !dayRuleData.is_vip &&
            dayRuleData.retroactive_num == dayRuleData.total_retroactive_num
          "
        >
          开通立享7项无敌权益
        </div>
        <img
          class="tag"
          src="https://cdn.vgn.cn/static/newnewnew/222/Slice%202%402x%20(2).png"
          alt=""
        />
        <div style="padding: 20px; z-index: 33; position: relative">
          断签3天内可以使用瓶盖进行补签哦，当月还有{{
            dayRuleData.total_retroactive_num - dayRuleData.retroactive_num
          }}次补签机会，是否使用{{ dayRuleData.missing_days }}*100瓶盖进行补签？
        </div>
        <div class="flex-row">
          <div
            class="button-unsign"
            style="
              border-top: 1px solid #eaeaea;
              border-right: 1px solid #eaeaea;
              color: #9aa1a9;
            "
            @click="abortSign"
          >
            放弃补签
          </div>
          <div
            class="button-unsign"
            v-if="
              !dayRuleData.is_vip &&
              dayRuleData.retroactive_num == dayRuleData.total_retroactive_num
            "
            style="
              border-top: 1px solid #eaeaea;
              color: #52b023;
              font-size: 16px;
              width: 50%;
            "
            @click="toMemberPage"
          >
            前往开通
          </div>
          <div
            class="button-unsign"
            v-else
            style="
              border-top: 1px solid #eaeaea;
              color: #52b023;
              font-size: 16px;
              width: 50%;
            "
            @click="GoToSign"
          >
            确定补签
          </div>
        </div>
      </div>
      <div class="unsign-think" @click="SignPopState = false">我再想想</div>
    </div>
    <div class="unsign-pop" v-if="taskState">
      <div class="content-pop">
        <div style="padding: 20px; z-index: 33; position: relative">
          当前瓶盖数量为{{ reward_point }}，需要{{ dayRuleData.missing_days*100 }}瓶盖才能补签成功，前往任务中心获得更多瓶盖吧
        </div>
        <div class="flex-row">
          <div
            class="button-unsign"
            style="
              border-top: 1px solid #eaeaea;
              border-right: 1px solid #eaeaea;
              color: #9aa1a9;
            "
            @click="thinkTash"
          >
            我再想想
          </div>
          <div
            class="button-unsign"
            style="
              border-top: 1px solid #eaeaea;
              color: #52b023;
              font-size: 16px;
              width: 50%;
            "
            @click="jumpBottle"
          >
            确定
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { List, Toast } from "vant";
import { Component, Vue } from "vue-property-decorator";
import BazzarApi from "@/api/bazaar.api";
import BlindBoxApi from "@/api/blind-box.api";
import LotteryCard from "@/views/bottle-page/components/LotteryCard.vue";
import LotteryCardFilter from "@/views/bottle-page/components/LotteryCardFilter.vue";
import CouponCard from "@/views/bottle-page/components/CouponCard.vue";
import SignPop from "@/views/welfare/components/SignPop.vue";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import InteractionLib from "@/utils/interaction.lib";
import moment from "moment";
import { observer, monthday } from "@/utils/common.util";
import router from "@/router";

@Component({
  components: {
    List,
    LotteryCard,
    CouponCard,
    SignPop,
    LotteryCardFilter,
  },
})
export default class BottlePage extends Vue {
  signDailyData = null;
  loadeded = false;
  finished = false;
  load = false;
  finish = false;
  SignPopState = false;
  page = 0;
  taskState = false;

  page_size = 10;
  showSignPopState = false;
  page1 = 0;
  dayRuleData = {};
  globalState = getModule(GlobalStateStore);
  page_size1 = 10;
  isFixed = true;
  get statusBar() {
    return this.globalState.statusBarHeight;
  }
  thinkTash(){
    this.taskState = false
  }
  GoToTask(){

  }
  async created() {
    await InteractionLib.getUserInfo();
    await this.globalState.getUserInfo();
    await this.initSign()
    await this.freeBlindBox();
  }
  async daily() {
    if (!this.globalState.userToken) return;
    this.signDailyData = await BazzarApi.signDailyDay().catch((err) =>{
      return
    });
    if(this.signDailyData?.point){
      this.showSignPopState = true;
      await this.globalState.getUserInfo();
      InteractionLib.updateUserInfo();
      this.reward_days = this.globalState.userInfo.reward_days
    }
  }
  async initSign(){
    if (this.globalState.userToken) {
      await this.GetUnSign();
      if (this.dayRuleData.missing_days == 0) {
        this.daily();
      } else {
        if(this.dayRuleData.is_vip&&this.dayRuleData.retroactive_num == this.dayRuleData.total_retroactive_num&&this.dayRuleData.missing_days != 0){
          Toast('本月补签次数已用完')
          await this.daily();
          await this.GetUnSign();
          this.reward_days = this.reward_days + 1
          this.SignPopState = false;
        }else{
          // const userInfo = await this.globalState.getUserInfo();
          // const day = moment().diff(
          //   moment(userInfo.reward_days_time * 1000),
          //   "days"
          // );
          // console.log(day,'距离。。。。。。')
          // if(day == 0){
            this.SignPopState = true;
          // }  
        }
      }
    }
  }
  async mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    observer("refreshInit", async () => {
      await this.freeBlindBox();
      await this.initSign()
      if (!this.globalState.userInfo && this.globalState.userToken) {
        await this.globalState.getUserInfo();
      }
    });
  }
  async abortSign() {
    this.SignPopState = false;
    await this.daily();
    await this.GetUnSign();
    this.reward_days = this.reward_days + 1
  }
  toSign() {
    this.SignPopState = true;
  }
  get unSign() {
    if (this.dayRuleData.missing_days == 0) return;
    return `color:gray`;
  }
  toMemberPage() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/member-page`);
    }
    return router.push({ name: "memberpage" });
  }
  toBlindBoxHandler() {
    const { box_id } = this.BlindBoxFreeData;
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/blind-box/${box_id}`
      );
    }
    location.href = `${location.origin}/blind-box/${box_id}`;
  }
  toCheckDetail() {
    this.globalState.getUserInfo();
    if (this.globalState.userInfo) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(
          `${process.env.SITE_CONFIG.TASK_SITE}/points`
        );
      }
      location.href = `${process.env.SITE_CONFIG.TASK_SITE}/points`;
    }
  }
  get covers() {
    const { pictures = [] } = this.BlindBoxFreeData;
    const len = pictures.length - 5;
    const n = len > 0 ? Array(len).fill("") : [];
    console.log(this.BlindBoxFreeData);

    return [...pictures, ...n];
  }

  get reward_days() {
    const { old_daily_reward, missing_days } = this.dayRuleData;
    if (missing_days > 0) {
      return old_daily_reward || 0;
    }
    return this.globalState.userInfo?.reward_days || 0;
  }
  get reward_list(){
    return String(this.reward_days).split('').reverse()
  }
  get reward_point() {
    const { userInfo } = this.globalState;
    return userInfo?.reward_point || 0;
  }
  get userInfo() {
    return this.globalState.userInfo;
  }
  confirm() {
    this.showSignPopState = false;
    document.body.style.overflow = "";
  }
  BlindBoxFreeData = {};

  async GoToSign() {
    if(this.reward_point < this.dayRuleData.missing_days*100){
      this.SignPopState = false;
      return this.taskState = true
    }
    const res = await BazzarApi.GoSign().catch((err) => {
      this.SignPopState = false;
      Toast(err)
      return 
    });
    if (res.num) {
      this.daily()
      this.reward_days = res.num+1;
      this.SignPopState = false;
      this.dayRuleData.missing_days = 0;
    }
  }
  async GetUnSign() {
    this.dayRuleData = await BazzarApi.getUnSign();
  }
  async GoSign() {
    const res = await BazzarApi.GoSign();
  }
  async freeBlindBox() {
    this.BlindBoxFreeData = await BazzarApi.getBlindBoxFree();
  }
  jumpBottle() {
    this.globalState.getUserInfo();
    if (this.globalState.userInfo) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(
          `${process.env.SITE_CONFIG.TASK_SITE}`
        );
      }
      location.href = `${process.env.SITE_CONFIG.TASK_SITE}`;
    }
  }
  get SignT() {
    return this.reward_list[3]||0
  }
  get SignH() {
    
    return this.reward_list[2]||0
  }
  get SignTW() {
   
    return this.reward_list[1]||0
  }
  get SignO() {
    
    return this.reward_list[0]||0
  }
  get PosLottery() {
    if (this.checkId != 1) {
      return {
        position: "absolute",
        left: "-1000px",
        top: "-1000px",
      };
    }
    return;
  }
  
  get TranX30() {
    if (this.checkId == 1) return `transform:translateX(100px)`;
  }
  handleScroll(e) {
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop; // 滚动条偏移量
    this.isFixed = scrollTop == 0 ? true : false; //
  }
  checkId = 0;
  BottleChange() {
    if (!this.checkId) return;
    this.checkId = 0;
  }

  BottleGuess() {
    if (this.checkId) return;
    this.checkId = 1;
  }

  get transColor() {
    if (!this.isFixed) {
      return `color:black;padding-top:${this.statusBar * 1.5}px;`;
    }
    return `padding-top:${this.statusBar * 1.5}px;`;
  }

  CounponData = [];
  lotteryData = [];
  async loadCouponData() {
    this.page++;
    const { page, page_size, CounponData } = this;
    let rows = await BlindBoxApi.getCounponData(page, page_size);
    this.loadeded = false;
    if (!rows.length || rows.length < this.page_size) this.finished = true;
    this.CounponData = [...CounponData, ...rows];
  }

  async loadData() {
    this.page1++;
    const { page1, page_size1, lotteryData } = this;
    let rows = await BlindBoxApi.getlotteryData(page1, page_size1);
    rows = rows.data;
    this.load = false;
    if (!rows.length || rows.length < this.page_size1) this.finish = true;
    this.lotteryData = [...lotteryData, ...rows];
  }
}
</script>

<style lang="scss" scoped>
.unsign-pop {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .unsign-think {
    color: #cccccc;
    font-size: 14px;
    text-decoration: underline;
    margin-top: 20px;
  }
  .content-pop {
    width: 80%;
    background: white;
    position: relative;
    border-radius: 6px;
    font-weight: 800;
    .vip-tag {
      background: linear-gradient(
        135deg,
        #ffedcf 0%,
        #fdcc75 47%,
        #f7d28c 100%
      );
      color: #804400;
      font-size: 12px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      height: 30px;
      padding: 0 12px;
      display: flex;
      align-items: center;
    }
    .tag {
      right: 6px;
      width: 74px;
      height: 60px;
      top: -20px;
      position: absolute;
    }
    .flex-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .button-unsign {
        width: 50%;
        font-size: 16px;
        padding: 19px 37px;
        text-align: center;
      }
    }
  }
}
.blind-box-free {
  width: 90px;
  height: 82px;
  border-radius: 2px;
  background: linear-gradient(133deg, #ff9260 0%, #ff655e 100%);
  padding-top: 4px;
  position: relative;
}
.blind-box-pos-receive-un {
  position: absolute;
  right: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.35);
  font-size: 10px;
  font-weight: 800;
  color: white;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
}
.blind-box-pos-receive {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 33px;
  height: 14px;
  background: linear-gradient(133deg, #ff9260 0%, #ff7b60 100%);
  font-size: 10px;
  color: white;
  font-weight: 800;
  border-radius: 2px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}
.blind-box-name {
  font-size: 12px;
  font-weight: 800;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(133deg, #ff9260 0%, #ff655e 100%);
  border-radius: 8px;
  height: 20px;
}
.bottle-page-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  position: relative;
  margin-top: 25px;
  .cut-sign-content {
    position: absolute;
    top: -30px;
    left: 0;
    height: 22px;
    font-size: 10px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    background: linear-gradient(65deg, #ffd66d 0%, #fff1a5 100%);
    img {
      width: 28.5px;
      height: 22px;
    }
  }
}
.tran-pos {
  position: absolute;
  width: 10px;
  height: 10px;
  background: linear-gradient(65deg, #ffd66d 0%, #fff1a5 100%);
  top: -15px;
  left: 15px;
  transform: rotate(45deg);
  z-index: 0;
}
.bottle-page-time {
  display: flex;
  align-items: flex-end;
  .bottle-page-time-content {
    background: url("https://cdn.vgn.cn/static/downloadpage/Subtract%402x.png");
    color: black;
    font-weight: bold;
    font-size: 48px;
    width: 39px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
    position: relative;
    margin-right: 7px;
    .bottle-page-time-content-line {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background: white;
    }
  }
}
.blind-box-covers {
  overflow: hidden;
  height: 57px;
  margin: 0 5px 0 5px;
  background: #ffffff;
  position: relative;
  background: #000000;

  .cover {
    --wh: 57px;
    position: absolute;
    @include full;

    &-0 {
      margin: 0 auto;
      width: var(--wh);
      height: var(--wh);
      z-index: 9;
    }

    &-1,
    &-2 {
      right: unset;
      left: -2px;
    }

    &-2 {
      top: calc(var(--wh) / 2 + 2px);
    }

    &-4 {
      top: calc(var(--wh) / 2 + 2px);
    }

    &-3,
    &-4 {
      left: unset;
      right: -2px;
    }

    &-1,
    &-2,
    &-3,
    &-4 {
      width: calc((100% - var(--wh)) / 2);
      height: calc(var(--wh) / 2);
    }
  }

  &:before {
    content: "";
    position: absolute;
    @include full;
    background: rgba(0, 0, 0, 0.35);
    z-index: 10;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.bottle-page-top {
  background: url("https://cdn.vgn.cn/static/downloadpage/%E5%A4%B4%E9%83%A8%402x%20(3).png");
  background-size: 100% 100%;
  width: 100%;
  height: 182px;
}
.bottle-page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  color: white;
  z-index: 999;
  background: transparent;
  padding: 0 16px;
  box-sizing: border-box;
  .bottle-page-title-back {
    width: 10px;
    height: 10px;
  }
  .bottle-page-title-name {
    font-size: 16px;
    margin-left: 30px;
  }
  .bottle-page-title-rule {
    font-size: 12px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.bottle-page-num-content {
  margin: -50px 12px 12px 10px;
  border-radius: 47px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  .bottle-page-num-content-left {
    font-size: 14px;
    color: #969696;
  }
  .bottle-page-num-content-nums {
    font-size: 28px;
    color: #222731;
    font-weight: 800;
    display: flex;
    margin-left: 40px;
    align-items: center;
    img {
      width: 21px;
      height: 21px;
    }
  }
  .bottle-page-num-content-right {
    font-size: 12px;
    color: #969696;
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
    }
  }
}
.bottle-page-sign-content {
  margin: 0 12px;
  border-radius: 6px;
  background: #ffffff;
  padding: 12px 16px;
  .bottle-page-sign-content-left {
    font-size: 14px;
    color: black;
    font-weight: 800;
    padding: 0 0 12px 0;
  }
  .bottle-page-button-more {
    border-radius: 4px;
    background: linear-gradient(270deg, #ff2626 0%, #ff6e4e 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: white;
    font-weight: 800;
    height: 44px;
    margin-top: 24px;
  }
}
.bottle-page-swipe {
  position: relative;
  padding: 20px 12px;
  .bottle-page-swipe-content {
    display: flex;
    align-items: center;
    color: #969696;
    font-weight: 800;
    .bottlePageSwipeActive {
      color: #222731;
      z-index: 9;
    }
  }
  .bottle-page-swipe-line {
    position: absolute;
    background: linear-gradient(90deg, #ff6c4d 0%, rgba(255, 110, 78, 0) 100%);
    width: 79px;
    height: 8px;
    bottom: 20px;
    left: 12px;
    transition: all 0.3;
  }
}
.bottle-change-card-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 12px;
}
</style>