<template>
  <div
    class="bottle-change-card"
    :style="{
      width: CardWidth + 'px',
    }"
    @click="toStoreHandler"
  >
    <div class="bottle-change-card-img">
      <img
        :style="{
          width: CardWidth - 8 + 'px',
          height: CardWidth * 0.635 + 'px',
          'object-fit': 'cover',
          'border-radius': '4px'
        }"
        :src="items.cover"
        alt=""
      />
      <div class="bottle-change-card-cdkey">CDKEY</div>
    </div>
    <div class="bottle-change-title">{{ items.title }}</div>
    <div class="bottle-change-discount_tag" v-if="items.discount_tag">
      {{ items.discount_tag }}
    </div>
    <div class="bottle-change-price">
      <img
        style="width: 16px; height: 16px; margin-bottom: 2px"
        src="https://cdn.vgn.cn/static/downloadpage/%E7%93%B6%E7%9B%96%402x%20(1).png"
        alt=""
      />
      <div style="margin-left: 4px">{{ items.point }}瓶盖</div>
      <div class="bottle-change-price-from">¥{{ items.bazaar_price }}</div>
    </div>
  </div>
</template>

<script>
import InteractionLib from "@/utils/interaction.lib";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class CouponCard extends Vue {
  @Prop() items;
  get CardWidth() {
    const { clientWidth } = document.body;
    return (clientWidth - 32) / 2;
  }
  toStoreHandler() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/sp-store/${this.items.id}`
      );
    }
    this.$router.push({ path: `/sp-store/${this.items.id}` });
  }
}
</script>

<style lang="scss" scoped>
.bottle-change-card {
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 7px;
  padding: 4px;
  box-sizing: border-box;
  .bottle-change-title {
    font-size: 13px;
    font-weight: 800;
    color: #293341;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .bottle-change-discount_tag {
    background: #f5f5f5;
    border-radius: 2px;
    font-size: 10px;
    font-weight: 800;
    display: inline;
    padding: 2px 6px;
    color: #646464;
  }
  .bottle-change-price {
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    margin-top: 6px;
    color: #f43041;
    .bottle-change-price-from {
      font-size: 11px;
      color: #ccc;
      text-decoration: line-through;
      margin-left: 2px;
    }
  }
  .bottle-change-card-img {
    position: relative;
    .bottle-change-card-cdkey {
      background: rgba($color: #121212, $alpha: 0.5);
      border-radius: 2px;
      color: #ffffff;
      font-size: 10px;
      width: 35px;
      height: 14px;
      padding: 0 4px;
      position: absolute;
      bottom: 10px;
      left: 4px;
    }
    img {
      object-fit: cover;
    }
  }
}
</style>