var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottle-change-card",style:({
    width: _vm.CardWidth + 'px',
  }),on:{"click":_vm.toStoreHandler}},[_c('div',{staticClass:"bottle-change-card-img"},[_c('img',{style:({
        width: _vm.CardWidth - 8 + 'px',
        height: _vm.CardWidth * 0.635 + 'px',
        'object-fit': 'cover',
        'border-radius': '4px'
      }),attrs:{"src":_vm.items.cover,"alt":""}}),_c('div',{staticClass:"bottle-change-card-cdkey"},[_vm._v("CDKEY")])]),_c('div',{staticClass:"bottle-change-title"},[_vm._v(_vm._s(_vm.items.title))]),(_vm.items.discount_tag)?_c('div',{staticClass:"bottle-change-discount_tag"},[_vm._v(" "+_vm._s(_vm.items.discount_tag)+" ")]):_vm._e(),_c('div',{staticClass:"bottle-change-price"},[_c('img',{staticStyle:{"width":"16px","height":"16px","margin-bottom":"2px"},attrs:{"src":"https://cdn.vgn.cn/static/downloadpage/%E7%93%B6%E7%9B%96%402x%20(1).png","alt":""}}),_c('div',{staticStyle:{"margin-left":"4px"}},[_vm._v(_vm._s(_vm.items.point)+"瓶盖")]),_c('div',{staticClass:"bottle-change-price-from"},[_vm._v("¥"+_vm._s(_vm.items.bazaar_price))])])])}
var staticRenderFns = []

export { render, staticRenderFns }