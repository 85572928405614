<template>
  <div class="bottole-page-big-card" @click="toLotteryHandler" v-if="this.items.awarded_number == null">
    <img
      :src="items.lottery_image"
      alt=""
      :style="{
        width: CardWidths + 'px',
        height: CardWidths * 0.73 + 'px',
        'object-fit': 'cover',
        'border-radius': '4px',
      }"
    />
    <div class="bottole-page-big-card-title">{{ items.lottery_name }}</div>
    <div class="bottole-page-big-card-content">
      <div>{{ openLottery }}</div>
      <div
        class="bottole-page-big-card-content-box"
        v-if="wordLottery == '正在开奖'"
      >
        {{ wordLottery }}
      </div>
      <div class="bottole-page-big-card-content-box-ready" v-else>
        {{ wordLottery }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { momentFormat } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class LotteryCard extends Vue {
  @Prop() items;
  get CardWidths() {
    const { clientWidth } = document.body;
    return clientWidth - 40;
  }
  get wordLottery() {
    return this.items.awarded_number != null
      ? "已开奖"
      : moment(this.items.cutoff_time * 1000).diff(moment()) <= 0
      ? "正在开奖"
      : "进行中";
  }
  get openLottery() {
    return momentFormat(this.items.awarded_time, false, "/");
  }
  toLotteryHandler() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${process.env.SITE_CONFIG.BASE_URL}/h5/?activity_id=${this.items.id}`
      );
    }
    location.href = `${process.env.SITE_CONFIG.BASE_URL}/h5/?activity_id=${this.items.id}`;
  }
}
</script>

<style lang="scss" scoped>
.bottole-page-big-card {
  margin: 0 12px;
  background: white;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 6px;
  .bottole-page-big-card-title {
    font-size: 14px;
    font-weight: 800;
    color: #121212;
    padding: 10px 0 4px 0;
  }
  .bottole-page-big-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #969696;
    padding-bottom: 8px;
    .bottole-page-big-card-content-box {
      border-radius: 2px;
      border: 1px solid red;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 12px;
      color: red;
    }
    .bottole-page-big-card-content-box-ready {
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 2px;
      color: #969696;
      background: #eee;
      padding: 2px 7px;
    }
  }
}
</style>